import {Fragment, useRef} from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import HomeConsultantCard from '../../Screens/Consultants/components/HomeConsultantCard';
import './Home.css';
import CourseCard from '../../UI/CourseCard';
import InitiativeCard from '../../UI/initiative-card/InitiativeCard';
import Story from '../../components/story/Story';
import BankQuestionsContent from '../../components/bank-questions-content/BankQuestionsContent';
import MainButton from '../../UI/main-button/MainButton';
import TrackingCard from '../../UI/tracking-card/TrackingCard';
import {useLocation, useNavigate} from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {NavLink} from 'react-router-dom';
import FollowupCard from '../../UI/followup-card/InitiativeCard';
import {useEffect, useState} from 'react';
import {getHomeModules} from './Home.service';
import BankQuestionItem from './BankQuestionItem';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {changeNotificationNumber, setConsStatus} from '../../store/userSlice';
import {isUserLoggedIn} from '../../auth/utiles';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {database} from '../../Screens/Consultants/components/firebase';
import {
  ref,
  orderByKey,
  endAt,
  get,
  limitToLast,
  query,
  onValue,
  off,
  update,
  set,
} from 'firebase/database';
import { getChatDomain } from '../../helpers/getChatDomain';
const Home = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [data, setData] = useState(null);
  const consStatus = useAppSelector(state => state.user.consStatus);
  const location = useLocation();

  const isCons = localStorage.getItem('isCons');

  const Logged = isUserLoggedIn();

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const [activeConsultantTab, setActiveConsultantTab] = useState('consultants');
  const [activeInitiativesTab, setActiveInitiativesTab] =
    useState('initiatives');
  const [activeFollowUpTab, setActiveFollowUpTab] = useState('single');
  const [activeCoursesTab, setActiveCoursesTab] = useState('recorded');

  const chatListen = async () => {
    let myId = localStorage.getItem('id');
    let refPath = ref(database, `${getChatDomain()}/${myId}`);
    onValue(refPath, snap => {
      let val = snap.val();
      if (!val) {
        dispatch(changeNotificationNumber(0));
      }

      const messenger = val;
      let readCount = 0;
      Object.values(messenger).forEach(lastObject => {
        const keys = Object.keys(lastObject);
        let lastMessage = Object.keys(lastObject).sort((a, b) => b - a)[
          Object.keys(lastObject).length - 1
        ];
        const lastItem = lastObject[lastMessage];
        if (lastItem.notRead) {
          readCount++;
        }

        dispatch(changeNotificationNumber(readCount));
      });
    });
  };

  useEffect(() => {
    getData();
    chatListen();
  }, []);
  useEffect(() => {
    setIsLoggedIn(Logged);
  }, [location.pathname]);

  const getData = async () => {
    let result = await getHomeModules(i18n.resolvedLanguage, Intl.DateTimeFormat().resolvedOptions().timeZone);
    console.log('home response', result.response);
    if (result.response.status) {
      dispatch(
        setConsStatus(result?.response?.data?.consultation_request_status),
      );
      setData(result.response.data);
    }
  };

  const navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 3,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 2,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };
  return (
    <Fragment>
      <div className="mb-5 pb-5 d-none">
        <Container>
          <Row>
            <Col lg="12">
              <h1
                className="m-5 text-center"
                style={{fontWeight: 'bold', color: '#7b7b7b'}}>
                Stories
              </h1>
            </Col>
            {Array.from({length: 6}, (_, i) => (
              <Col lg="2">
                <Story />
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div className="pt-md-5 pb-md-5">
        <Container>
          <Row>
            <Col
              lg="7"
              className="d-flex justify-content-center align-items-center">
              <div>
                <h1
                  style={{
                    fontSize: '60px',
                    fontWeight: 'bold',
                    color: '#7b7b7b',
                  }}
                  className="mb-4">
                  {t('Welcome To')} <p className="main-color">{t('Nurabi')}</p>
                </h1>
                <p className="top-p" style={{fontFamily: "'Stylish', serif"}}>
                  {t('nurbiMainDesc')}
                </p>
                <div
                  onClick={() => {
                    navigate('contact-us');
                  }}
                  style={{width: 'fit-content'}}
                  className='mt-4'>
                  <MainButton text={'Contact Us'} />
                </div>
              </div>
            </Col>

            <Col lg="5">
              <img src="/1.jpg" className='w-100' style={{ padding: '1rem' , borderRadius: '2rem' }} />
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="mb-5 pb-5 pt-5"
        style={{background: 'rgb(247, 247, 247)'}}>
        <Container>
          <Row>
            <Col
              lg="12"
              className="mb-4 d-flex justify-content-between align-items-center">
              <h1
                className="m-0"
                style={{fontWeight: 'bold', color: '#7b7b7b'}}>
                {t('Teachers')}
              </h1>
              <NavLink
                to={'/consultants/get-teachers'}
                className={'see-more secondry-color'}>
                {t('See More')}
              </NavLink>
            </Col>
            {data?.teachers?.data && (
              <Carousel responsive={responsive} infinite={true}>
                {data?.teachers.data.map(i => (
                  <div className="carouselItem">
                    <HomeConsultantCard
                      consultant={{
                        id: i.id,
                        avatar: i.avatar,
                        username: i.username,
                        first_session_free: i.first_session_free,
                        available_sessions: i.available_sessions,
                        fields_names: i.fields_names,
                        languages_names: i.languages_names,
                        consultation_info: {type: 'teacher'},
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </Row>
        </Container>
      </div>

      <div className="mb-5">
        <Container>
          <Row>
            <Col
              lg="12"
              className="mb-4 d-flex justify-content-between align-items-center">
              <h1
                className="m-0"
                style={{fontWeight: 'bold', color: '#7b7b7b'}}>
                {t('Consultations')}
              </h1>
              <NavLink
                to={'/consultants/get-consultants'}
                className={'see-more secondry-color'}>
                {t('See More')}
              </NavLink>
            </Col>

            <Col lg="12" className="mb-md-5">
              <div className="text-center">
                <button
                  className={`custom-tab-button ${
                    activeConsultantTab == 'consultants' ? 'active' : ''
                  }`}
                  onClick={() => setActiveConsultantTab('consultants')}>
                  {t('Consultants')}
                </button>
                <button
                  className={`custom-tab-button ${
                    activeConsultantTab == 'coaches' ? 'active' : ''
                  }`}
                  onClick={() => setActiveConsultantTab('coaches')}>
                  {t('Coaches')}
                </button>
              </div>
            </Col>

            {activeConsultantTab == 'consultants' ? (
              <div>
                {data?.consultants?.data && (
                  <Carousel responsive={responsive} infinite={true}>
                    {data?.consultants.data.map(i => (
                      <div className="carouselItem">
                        <HomeConsultantCard
                          consultant={{
                            id: i.id,
                            avatar: i.avatar,
                            username: i.username,
                            first_session_free: i.first_session_free,
                            available_sessions: i.available_sessions,
                            fields_names: i.fields_names,
                            languages_names: i.languages_names,
                            consultation_info: {type: 'consultant'},
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            ) : (
              <div>
                {data?.organization?.data && (
                  <Carousel responsive={responsive} infinite={true}>
                    {data?.organization.data.map(i => (
                      <div className="carouselItem">
                        <HomeConsultantCard
                          consultant={{
                            id: i.id,
                            avatar: i.avatar,
                            username: i.username,
                            first_session_free: i.first_session_free,
                            available_sessions: i.available_sessions,
                            fields_names: i.fields_names,
                            languages_names: i.languages_names,
                            consultation_info: {type: 'consultant'},
                          }}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            )}
          </Row>
        </Container>
      </div>

      { data?.initiatives?.data?.length  || data?.initiative_packages?.data?.length ?                     
      <div className="mb-5 pt-md-5" style={{background: 'rgb(247, 247, 247)'}}>
        <Container>
          <Row>
            <Col
              lg="12"
              className="mb-4 d-flex justify-content-between align-items-center">
              <h1
                className="m-0"
                style={{fontWeight: 'bold', color: '#7b7b7b'}}>
                {t('Initiatives')}
              </h1>
              <NavLink
                to={'/user/initiatives/'}
                className={'see-more secondry-color'}>
                {t('See More')}
              </NavLink>
            </Col>

            <Col lg="12" className="mb-md-5">
              <div className="text-center">
                <button
                  className={`custom-tab-button ${
                    activeInitiativesTab == 'initiatives' ? 'active' : ''
                  }`}
                  onClick={() => setActiveInitiativesTab('initiatives')}>
                  {t('Single Initiatives')}
                </button>
                <button
                  className={`custom-tab-button ${
                    activeInitiativesTab == 'initiativesPackages'
                      ? 'active'
                      : ''
                  }`}
                  onClick={() =>
                    setActiveInitiativesTab('initiativesPackages')
                  }>
                  {t('Multi Level Initiatives')}
                </button>
              </div>
            </Col>

            {activeInitiativesTab == 'initiatives' ? (
              <div>
                {data?.initiatives?.data && (
                  <Carousel responsive={responsive} infinite={true}>
                    {data?.initiatives.data.map(i => (
                      <div className="carouselItem">
                        <InitiativeCard
                          id={i.id}
                          name={i.name}
                          provider_name={i.provider_name}
                          start={i.start_date}
                          end={i.end_date}
                          price={i.price}
                          image={i.image}
                          isPackage={false}
                          tracking_type={i.tracking_type}
                          level={i.level}
                          selected_enrolled_user_id={i.selected_enrolled_user_id ? i.selected_enrolled_user_id : null }
                          user_currency={i.user_currency}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            ) : (
              <div>
                {data?.initiative_packages?.data && (
                  <Carousel responsive={responsive} infinite={true}>
                    {data?.initiative_packages.data.map(i => (
                      <div className="carouselItem">
                        <InitiativeCard
                          id={i.id}
                          name={i.name}
                          provider_name={i.provider_name}
                          start={i.start_date}
                          end={i.end_date}
                          price={i.price}
                          image={i.image}
                          isPackage={true}
                          tracking_type={i.tracking_type}
                          level={i.level}
                          selected_enrolled_user_id={i.selected_enrolled_user_id ? i.selected_enrolled_user_id : null}
                          user_currency={i.user_currency}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            )}
          </Row>
        </Container>
      </div>
      : '' }

      { data?.follow_up_systems?.data?.length  || data?.follow_up_system_packages?.data?.length ?  
      <div className="mb-5">
        <Container>
          <Row>
            <Col
              lg="12"
              className="mb-4 d-flex justify-content-between align-items-center">
              <h1
                className="m-0"
                style={{fontWeight: 'bold', color: '#7b7b7b'}}>
                {t('Follow-up Systems')}
              </h1>
              <NavLink
                to={'/coming-soon'}
                className={'see-more secondry-color'}>
                {t('See More')}
              </NavLink>
            </Col>

            <Col lg="12" className="mb-md-5">
              <div className="text-center">
                <button
                  className={`custom-tab-button ${
                    activeFollowUpTab == 'single' ? 'active' : ''
                  }`}
                  onClick={() => setActiveFollowUpTab('single')}>
                  {t('Single Systems')}
                </button>
                <button
                  className={`custom-tab-button ${
                    activeFollowUpTab == 'multi' ? 'active' : ''
                  }`}
                  onClick={() => setActiveFollowUpTab('multi')}>
                  {t('Multi Level Systems')}
                </button>
              </div>
            </Col>

            {activeFollowUpTab == 'single' ? (
              <div>
                {data?.follow_up_systems?.data && (
                  <Carousel responsive={responsive} infinite={true}>
                    {data?.follow_up_systems.data.map(i => (
                      <div className="carouselItem">
                        <FollowupCard
                          name={i.name}
                          provider_name={i.provider_name}
                          price={i.price}
                          image={i.image}
                          user_currency={i.user_currency}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            ) : (
              <div>
                {data?.follow_up_system_packages?.data && (
                  <Carousel responsive={responsive} infinite={true}>
                    {data?.follow_up_system_packages.data.map(i => (
                      <div className="carouselItem">
                        <FollowupCard
                          name={i.name}
                          provider_name={i.provider_name}
                          price={i.price}
                          image={i.image}
                          user_currency={i.user_currency}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            )}
          </Row>
        </Container>
      </div>
      : '' }

      { data?.recorded_courses?.data?.length  || data?.live_courses?.data?.length ? 
      <div
        className="mb-5 pt-md-5 pb-md-5"
        style={{background: 'rgb(247, 247, 247)'}}>
        <Container>
          <Row>
            <Col
              lg="12"
              className="mb-4 d-flex justify-content-between align-items-center">
              <h1
                className="m-0"
                style={{fontWeight: 'bold', color: '#7b7b7b'}}>
                {t('Courses')}
              </h1>
              <NavLink
                to={'/courses'}
                className={'see-more secondry-color'}>
                {t('See More')}
              </NavLink>
            </Col>

            <Col lg="12" className="mb-md-5">
              <div className="text-center">
                <button
                  className={`custom-tab-button ${
                    activeCoursesTab == 'recorded' ? 'active' : ''
                  }`}
                  onClick={() => setActiveCoursesTab('recorded')}>
                  {t('Recorded Courses')}
                </button>
                <button
                  className={`custom-tab-button ${
                    activeCoursesTab == 'live' ? 'active' : ''
                  }`}
                  onClick={() => setActiveCoursesTab('live')}>
                  {t('Live Courses')}
                </button>
              </div>
            </Col>

            {activeCoursesTab == 'recorded' ? (
              <div>
                {data?.recorded_courses?.data && (
                  <Carousel responsive={responsive} infinite={true}>
                    {data?.recorded_courses.data.map(i => (
                      <div className="carouselItem">
                        <CourseCard
                          id={i.id}
                          image={i.image}
                          title={i.title}
                          provider_name={i.provider_name}
                          price={i.price}
                          is_user={i.is_user}
                          is_enrolled={i.is_enrolled ?? false }
                          user_currency={i.user_currency}
                          
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            ) : (
              <div>
                {data?.live_courses?.data && (
                  <Carousel responsive={responsive} infinite={true}>
                    {data?.live_courses.data.map(i => (
                      <div className="carouselItem">
                        <CourseCard
                          id={i.id}
                          image={i.image}
                          title={i.title}
                          provider_name={i.provider_name}
                          price={i.price}
                          is_user={i.is_user}
                          is_enrolled={i.is_enrolled ?? false }
                        />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            )}
          </Row>
        </Container>
      </div>
      : '' }

      <div className="pb-5">
        <Container>
          <Row>
            <Col lg="12">
              <h1
                className="m-5 text-center"
                style={{fontWeight: 'bold', color: '#7b7b7b'}}>
                {t('Bank Questions')}
              </h1>
            </Col>

            {data?.bank_questions.data.map((i, index) => (
              <div className="carouselItem">
                <BankQuestionItem
                  collapsed={index == 0 ? true : false}
                  question={i.question}
                  views={i.views}
                  likes={i.likes}
                  dislikes={i.dislikes}
                  answer={i.answer}
                  isLoggedIn={isLoggedIn}
                  id={i.id}
                  loadQuestions={getData}
                  is_liked={ i.hasOwnProperty('is_liked') ? i.is_liked : 0  }
                  is_disliked={ i.hasOwnProperty('is_disliked') ? i.is_disliked : 0  }
                />
              </div>
            ))}

            <Col
              md="2"
              className="mt-3 m-auto"
              onClick={() => {
                navigate(`/bank-questions`);
              }}>
              <MainButton text={t('See More')} />
            </Col>
          </Row>
        </Container>
      </div>
      {(isCons !== 'true' || isCons == null || !isCons) &&
        isLoggedIn &&
        (consStatus == null || consStatus == 'rejected') && (
          <div className="pb-5 mt-5">
            <Container>
              <Row>
                <Col md={{span: 12}} className="join-consultants">
                  <Row>
                    <Col md={7}>
                      <div
                        className="d-flex justify-content-center align-items-center pt-md-4"
                        style={{flexDirection: 'column'}}>
                        <h1>{t('Join our team of consultants')}</h1>

                        <div
                          className="mt-4"
                          style={{width: 'fit-content'}}
                          onClick={() => {
                            navigate(`/user/consultation/request`);
                          }}>
                          <MainButton text={t('Start Now')} />
                        </div>
                      </div>
                    </Col>

                    <Col md={5}>
                      <img src={'/consultant2.png'} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        )}
    </Fragment>
  );
};

export default Home;
