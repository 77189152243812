import '../style/CourseItem.css';
import RateAndPrice from '../../../components/RateAndPrice/RateAndPrice';
import {useNavigate} from 'react-router-dom';
import {CourseItemProps} from '../interfaces/ICourses';
import {Enums} from '../../../helpers/Enums';
import { colors } from '../../../constants/colors';
const CourseItem = ({course}: CourseItemProps) => {
  const navigate = useNavigate();

  function handleClick() {
    course.is_enrolled
      ? navigate(`/lessons/${course?.id}`, {state: {course: course}})
      : navigate(`/courses/${course?.id}`, {state: {course: course}});
  }

  return (
    <div className="course-item d-flex row" onClick={handleClick}>
      <span
        className={`course-type ${
          course?.is_live === 1 ? 'live-course-type' : 'recorded-course-type'
        }`}>
        {course?.is_live === 1 ? Enums.Live : Enums.Recorded}
      </span>
      <div className="course-image col-md-3">
        {course.image || course.thumbnail ? (
          <img src={course?.thumbnail ? course?.thumbnail : course?.image} />
        ) : (
          <img
            src={'/nurabi.png'}
            alt="course-img"
            style={{
              width: '8rem',
              padding: '2rem',
              margin: 'auto',
              display: 'block',
              height: 'auto',
            }}
          />
        )}
      </div>
      <div className="course-details col-md-7">
        <h4>{course.title}</h4>
        {/*<p>{course.description}</p>*/}
        <p className="course-provider">By {course?.provider_data?.name}</p>


        {course?.is_limited == 1 &&
          !course?.is_enrolled &&
          course?.is_live == 1 && (
        <span
        
        style={{
          background: colors.maincolor,
          padding: '5px 10px',
          borderRadius: '10px',
          color:'#fff',
        }}>
          {
            course?.has_reach_limit == 1 ? `Sold out seats: ${course?.remaining_number} left of ${course?.limited_number} seats` : `Limited seats: ${course?.remaining_number} left of ${course?.limited_number} seats`
          }
          
        </span>
        )}


        <RateAndPrice
          ratings={course.ratings}
          ratings_count={course.ratings_count}
          subscribers={course.subscribers}
        />
      </div>
      {course.is_enrolled ? (
        <div className="col-md-2 course-price">
          <div className="discount-price">Enrolled</div>
        </div>
      ) : course.free ? (
        <div className="col-md-2 course-price">
          <div className="discount-price">Free</div>
        </div>
      ) : (
        <div className="col-md-2 course-price">
          <div className="discount-price">{course?.price} {course?.user_currency}</div>
        </div>
      )}
    </div>
  );
};

export default CourseItem;
