import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import './NavigationHeaderV2.css';
import classes from './NavigationHeaderV2.module.css';
import {FaCartArrowDown, FaFacebookMessenger, FaGlobe} from 'react-icons/fa';
import {useAppSelector} from '../../hooks/hooks';
import {isUserLoggedIn, getUserAvatar} from '../../auth/utiles';
import {useEffect, useState} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {getLanguage, setLanguage} from './getLang';
import MainButton from '../../UI/main-button/MainButton';
import Notifications from '../../Screens/Notifications/Notifications';

const NavigationHeaderV2 = () => {
  const {t} = useTranslation();
  const consStatus = useAppSelector(state => state.user.consStatus);
  const chatNumber = useAppSelector(state => state.user.notificationNumber);
  console.log('consStatus', consStatus);

  const Logged = isUserLoggedIn();
  const [isLoggedIn, setIsLoggedIn] = useState<string | null>(null);
  const [switchStatus, setSwitchStatus] = useState<string | null | undefined>(
    localStorage.getItem('switch') ?? '0',
  );
  const location = useLocation();
  const navigate = useNavigate();
  const isCons = localStorage.getItem('isCons');

  useEffect(() => {
    getLanguage();
    setIsLoggedIn(Logged);
  }, [location.pathname]);

  const userAvatar = getUserAvatar();

  const cartLength = useAppSelector(state => state.cart.totalProducts);

  return (
    <Navbar expand="lg">
      <Toaster position="top-right" />
      <Container className={`NavContainer ${classes.NavBar}`}>
        {switchStatus == '1' && (
          <Navbar.Brand
            href="/consultants/availablilty"
            className={`mt-2 mb-2 mr-md-5 ml-md-5 ${classes.Logo}`}>
            <img src="/nurabi.png" alt="img" />
          </Navbar.Brand>
        )}
        {switchStatus == '0' && (
          <Navbar.Brand
            href="/"
            className={`mt-2 mb-2 mr-md-5 ml-md-5 ${classes.Logo}`}>
            <img src="/nurabi.png" alt="img" />
          </Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-between">
          <Nav className="mr-md-auto">
          
            {switchStatus == '0' && (
              <NavLink
                to="/consultants/get-teachers"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Teachers')}
              </NavLink>
            )}

            {switchStatus == '0' && (
              <NavLink
                to="/consultants/get-consultants"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Consultations')}
              </NavLink>
            )}

            {switchStatus == '0' && (
              <NavLink
                to="/user/initiatives/"
                className={({isActive}) => `m-2 ${classes.MainNavHeaderLink}`}>
                {t('Initiatives')}
              </NavLink>
            )}

            {switchStatus == '0' && (
              <NavLink
                to="/user/trackings/list/"
                className={({isActive}) => `m-2 ${classes.MainNavHeaderLink}`}>
                {t('Follow-up Systems')}
              </NavLink>
            )}

            {switchStatus == '0' && (
              <NavLink
                to="/courses"
                className={({isActive}) => `m-2 ${classes.MainNavHeaderLink}`}>
                {t('Courses')}
              </NavLink>
            )}

            {switchStatus == '0' && (
              <NavLink
                to="/bank-questions"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Bank Questions')}
              </NavLink>
            )}

            {switchStatus == '1' && isLoggedIn && (
              <NavLink
                to="/consultants/availablilty"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Schedule')}
              </NavLink>
            )}

            {switchStatus == '1' && isLoggedIn && (
              <NavLink
                to="/consultant/booked-sessions"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Booked Sessions')}
              </NavLink>
            )}

            {switchStatus == '1' && isLoggedIn && (
              <NavLink
                to="/private/sessions/list"
                className={({isActive}) =>
                  isActive ? classes.active : `m-2 ${classes.MainNavHeaderLink}`
                }>
                {t('Private Sessions')}
              </NavLink>
            )}
          </Nav>

          <Nav className="ml-md-auto">
            {((isCons !== 'false' && isCons !== null) ||
              consStatus == 'accepted') && (
              <img
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (switchStatus == '0') {
                    localStorage.setItem('switch', '1');
                    setSwitchStatus('1');
                    navigate('/consultants/availablilty');
                  } else {
                    localStorage.setItem('switch', '0');
                    setSwitchStatus('0');
                    navigate('/');
                  }
                }}
                className={`${classes.Switcher}`}
                src={`/icons/transfer.png`}
              />
            )}
            {consStatus == 'pending' && (
              <img
                style={{cursor: 'pointer'}}
                onClick={() => {
                  toast.error(t('reqPending'));
                }}
                className={`${classes.Switcher}`}
                src={`/icons/deadline.png`}
              />
            )}
            {(isCons !== 'true' || isCons == null || !isCons) &&
              isLoggedIn &&
              (consStatus == null || consStatus == 'rejected') && (
                <NavLink
                  to="/user/consultation/request"
                  className={({isActive}) =>
                    isActive
                      ? classes.active
                      : `join-link m-2 ${classes.MainNavHeaderLink}`
                  }>
                  {t('join')}
                </NavLink>
              )}

            {isLoggedIn ? (
              <>
                {switchStatus == '0' && (
                  <NavLink
                    to="/cart"
                    className={`m-2 ${classes.MainNavHeaderLink} ${classes.NoramlNavItem}`}
                    style={{
                      background: 'rgb(255, 237, 238)',
                      borderRadius: '5px',
                      padding: '10px 12px 10px 10px',
                      position: 'relative',
                    }}>
                    {' '}
                    <span
                      className="cart-items-number"
                      style={{
                        background: '#fb5456',
                        position: 'absolute',
                        color: '#fff',
                        top: '-10px',
                        right: '-5px',
                        padding: '0px 7px',
                        borderRadius: '50%',
                        fontSize: '.9rem',
                      }}>
                      {cartLength}
                    </span>{' '}
                    <FaCartArrowDown size={25} color="#fb5456" />{' '}
                  </NavLink>
                )}

                <NavLink
                  to="/chatlist"
                  className={`m-2 ${classes.MainNavHeaderLink} ${classes.NoramlNavItem}`}
                  style={{
                    background: 'rgb(255, 237, 238)',
                    borderRadius: '5px',
                    padding: '10px 12px 10px 10px',
                    position: 'relative',
                  }}>
                  {' '}
                  <span
                    className="cart-items-number"
                    style={{
                      background: '#fb5456',
                      position: 'absolute',
                      color: '#fff',
                      top: '-10px',
                      right: '-5px',
                      padding: '0px 7px',
                      borderRadius: '50%',
                      fontSize: '.9rem',
                    }}>
                    {chatNumber}
                  </span>{' '}
                  <img src="/icons/chat.png" width={25} />{' '}
                </NavLink>

                <Notifications />

                <img
                  onClick={() => {
                    if (isCons !== 'false' && isCons !== null) {
                      if (switchStatus == '0') {
                        navigate('/show/user/profile');
                      } else {
                        navigate('/consultant/profile');
                      }
                    } else {
                      navigate('/show/user/profile');
                    }
                  }}
                  src={
                    userAvatar == 'null' ? `/icons/manHolder.png` : userAvatar!
                  }
                  alt="avatar"
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    padding: '2px',
                    boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.75)',
                    marginLeft: '2rem',
                    cursor: 'pointer',
                  }}
                />
              </>
            ) : (
              <NavLink
                to="/login"
                className={`login-register m-2 ${classes.MainNavHeaderLink}`}>
                {t('Login / Register')}
              </NavLink>
            )}

            {/*
            <NavLink
              onClick={ () => {
                if( i18n.resolvedLanguage == 'en' ){
                  localStorage.setItem('lang','ar')
                  i18n.changeLanguage('ar')
                }else{
                  localStorage.setItem('lang','en')
                  i18n.changeLanguage('en')
                }
                window.location.reload();
              } }
              to="#"
              className={({isActive}) =>
                isActive
                  ? classes.active
                  : `m-2 ${classes.MainNavHeaderLink} lang`
              }
              style={{color: '#08cad7 !important'}}>
              {' '}
              <FaGlobe /> { i18n.resolvedLanguage == 'en' ? 'العربية' : 'English' } 
            </NavLink>
            */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationHeaderV2;
