import {Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import classes from './style/TrackingDetailsAfterEnroll.module.css';
import {useLocation, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import TrackingDetailsCard from './TrackingDetailsCard';
import ActivitiesTab from './components/ActivitiesTab';
import RankingTab from '../Tracking/components/RankingTab';
import PersonalityAnalysisCard from '../Quiz/components/PersonalityAnalysisCard';
import ResultProgress from '../Tracking/components/ResultProgress';
import AreaChart from '../Tracking/components/AreaChart';
import {FaCircle} from 'react-icons/fa';
import {
  assignAndStartTrack,
  getTrackingsDetailsAfterEnroll,
  getmemberForAssign,
} from '../Tracking/Trackings.service';
import moment from 'moment';
import {TrackingType} from './interfaces/ITrackings';
import axios from 'axios';
import MainButton from '../../UI/main-button/MainButton';
import Modal from 'react-bootstrap/Modal';
import CancelButton from '../../UI/cancel-button/CancelButton';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import Card from 'react-bootstrap/Card';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {getTrackData, getDataAfterEnroll} from './Initiatives.service';
import InitiativeDetailsCard from './InitiativeDetailsCard';
import {useNavigate} from 'react-router-dom';
import {getDomain} from '../../helpers/getDomain';
import PopoverUi from './components/PopoverUi';
import { getLanguage,setLanguage } from '../../components/navigation-header-v2/getLang';
import {useTranslation} from 'react-i18next';
import { getHistory } from './Trackings.service';
import i18n from 'i18next';
import TrackingCard from './components/TrackingCard';

const TrackingDetailsAfterEnroll = () => {

  const {t} = useTranslation();

  const [originalLang, setOriginalLang] = useState(getLanguage());

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {id , userId } = useParams();

  const location = useLocation();

  
  //const [item, setItem] = useState(location.state.item);
  

  // const start_date = location.state.start_date;
  // const end_date = location.state.end_date;
  // const trackingDetails = location.state.tracking;

  //const userId = item?.selected_enrolled_user_id ?? localStorage.getItem('id');
  
  let token = localStorage.getItem('token');
  const [data, setData] = useState<TrackingType | undefined>();
  const [daysProgress, setDaysProgress] = useState<any>([]);
  const [report, setReport] = useState<any>();

  const [daySelected, setDaySelected] = useState<string | undefined>();

  const [members, setMembers] = useState<any>([]);

  const [showAssignUsers, setShowAssignUsers] = useState(false);

  const [member, setMember] = useState(null);

  const [selectedUsername, setSelectedUsername] = useState(null);

  const [selectedUserAvatar, setSelectedUserAvatar] = useState(null);

  const [selectedUserId, setSelectedUserId] = useState(null);

  
  const [historyList, setHistoryList] = useState<any>([]);

  

  

  const getAssignedUserVal = (e: any) => {
    console.log(e.target.value);
    setMember(e.target.value);
  };

  const redirectWithNewId = () => {
    setShowAssignUsers(false);

    window.location.replace(`/user/trackings/${id}/details/enrolled/${member}`)

    //getReport();
    //getData(null);
  };

  const series = [
    {
      name: 'Network',
      data: daysProgress,
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'area',
      height: 350,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    fill: {
      opacity: 0.8,
      type: 'pattern',
      pattern: {
        style: ['verticalLines', 'horizontalLines'],
        width: 5,
        height: 6,
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 9,
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      intersect: true,
      shared: false,
    },
    theme: {
      palette: 'palette1',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      title: {
        text: '',
      },
    },
  };

  useEffect(() => {
    getReport();
    getData(null);
    getHistoryTracks(id)
    //return () => {
      //i18n.changeLanguage(originalLang)
      //setLanguage(originalLang)
    //};
  }, []);

  const getData = async (date: string | null) => {
    dispatch(setLoading(true));
    let currentUserId = userId ?? localStorage.getItem('id');
    let result = await getTrackData(id, currentUserId);
    console.log('rerewrerere', result.response.data);

    let today = moment().locale('en').format('YYYY-MM-DD');
    let start = moment(result.response.data.start_date).locale('en');
    let end = moment(result.response.data.end_date).locale('en');
    let check =
      moment(today).isBetween(start, end) || moment(today).isSame(end);

    let theDate = daySelected
      ? daySelected
      : check
      ? moment().locale('en').format('YYYY-MM-DD')
      : moment(result.response.data.start_date)
          .locale('en')
          .format('YYYY-MM-DD');
    console.log('theDate', theDate);
    console.log(
      'result.response.data.start_date',
      result.response.data.start_date,
    );
    console.log('result.response.data.end_date', result.response.data.end_date);

    setDaySelected(date ?? theDate);
    console.log('date', date);

    if (result.response) {
      console.log('track result.response', result.response);

      try {
        var selectedId = null;
        if (member) {
          selectedId = member;
        } else {
          selectedId = userId;
        }
        let result = await getDataAfterEnroll(
          id,
          date
            ? date
            : theDate
            ? theDate
            : moment().locale('en').format('YYYY-MM-DD'),
          selectedId,
        );
        if (result.response) {
          
          /*
          if( result.response.data.lang == 'ar' ){
            i18n.changeLanguage('ar')
            setLanguage('ar')
          }else{
            i18n.changeLanguage('en')
            setLanguage('en')
          }
          */
          
          dispatch(setLoading(false));
          setData(result.response.data);
          setMembers(result.response.data.enrolled_members);
          console.log('selected user name', result.response.data);
          setSelectedUsername(result.response.data.selected_user_name);
          setSelectedUserAvatar(result.response.data.selected_user_avatar);
          setSelectedUserId(result.response.data.selected_enrolled_user_id);
          setMember(result.response.data.selected_enrolled_user_id);
        }
      } catch (error) {
        // console.log('gugu', error);
      }
    }
  };

 

  const getReport = async () => {
    let api = `${getDomain()}/api/trackingsystem/report/${id}?current_user_id=${userId}`;

    try {
      let result = await axios({
        url: api,
        method: 'get',

        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('reports', result?.data?.data);
      if (result.data.status) {
        setReport(result?.data?.data);
        let modfiedArray = [];
        for (const key in result?.data?.data.progress_per_days) {
          const value = result?.data?.data.progress_per_days[key];
          modfiedArray.push({
            x: `${moment(key).format('MMM DD YYYY')}`,
            y: Math.round(value),
          });
        }
        console.log('modfiedArray', modfiedArray);
        setDaysProgress(modfiedArray);
      }
    } catch (error) {}
  };

  const StartTrack = async () => {
    dispatch(setLoading(true));
    try {
      let selectedId = null;
      if (member) {
        selectedId = member;
      } else {
        selectedId = userId;
      }

      let data = new FormData();

      data.append('tracking_id', String(id));
      data.append('start_tracking', '1');
      data.append('members[0]', String(selectedId));

      let result = await assignAndStartTrack(data);
      if (result.response) {
        if (result.response.code == 202) {
          console.log('result.response.msg', result.response.msg);
          dispatch(setLoading(false));
        } else {
          setDaySelected(moment().locale('en').format('YYYY-MM-DD'));
          getData(moment().locale('en').format('YYYY-MM-DD'));
        }
      } else dispatch(setLoading(false));
    } catch (e) {
      console.log({e});
    }
  };

  const getHistoryTracks = async (id:any) => {
    dispatch(setLoading(true));
    let trackId = id;
    let userId = localStorage.getItem('id')

    let currentUserId = userId
      ? userId
      : data?.selected_enrolled_user_id
      ? data?.selected_enrolled_user_id
      : userId;
    try {
      let result = await getHistory(trackId, currentUserId);
      if( result.response ){
        setHistoryList(result.response.data);
        console.log('getHistory', result.response.data);
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      // console.log('gugu', error);
    }
  };

  return (
    <div className="mt-md-5">
      <Container>
        <Row className="d-flex justify-content-between mb-5">
          <Col xs={12} lg={4} className="mt-3 mb-3">
            <TrackingDetailsCard tracking={data!} getData={getData} />
          </Col>

          <Col xs={12} lg={8}>
            <div
              style={{
                padding: '1rem 2rem',
                border: '1px solid #bcbcbc',
                borderRadius: '15px',
              }}
              className="d-flex justify-content-between align-items-center mb-3 mt-3">
              <div>
                <img
                  src={selectedUserAvatar ?? '/icons/manHolder.png'}
                  style={{
                    width: '50px',
                    height: '50px',
                    margin: '0 10px',
                    borderRadius: '50%',
                  }}
                />
                <span>{selectedUsername}</span>
              </div>
              <div className="d-flex  align-items-center">
                <p
                  style={{color: 'red', cursor: 'pointer', margin: '0 5px'}}
                  onClick={() => setShowAssignUsers(true)}>
                  <strong>{t('SwitchUser')}</strong>
                </p>
                <PopoverUi name={selectedUsername} />
              </div>
            </div>
            <Tabs
              defaultActiveKey={'activities'}
              id="uncontrolled-tab-example"
              className="consultant-tabs">
              <Tab eventKey="activities" title={t('Activities')}>
              { data?.start_date && (
                <ActivitiesTab
                  tracking={data!}
                  daySelected={daySelected}
                  userId={userId!}
                  setDaySelected={i => {
                    getData(i);
                    setDaySelected(i);
                  }}
                />
              )}
              </Tab>

              <Tab eventKey="ranking" title={t('Ranking')}>
                <RankingTab userId={userId} />
              </Tab>

              <Tab eventKey="reports" title={t('Reports')}>
                <Row>
                  <Col sm="6">
                    <ResultProgress
                      report={report?.completed.toFixed(1)}
                      title={t('Completed')}
                      color={'#39b970'}
                      backColor={'#39b970'}
                    />
                  </Col>

                  <Col sm="6">
                    <ResultProgress
                      report={report?.remaining.toFixed(1)}
                      title={t('Remaining')}
                      color={'red'}
                      backColor={'#fb5456'}
                    />
                  </Col>

                  <Col md="12">
                    {daysProgress.length > 0 ? (
                      <Card className="text-center main-card">
                        <Card.Body className="mb-4 mt-3">
                          <Chart
                            options={options}
                            series={series}
                            type="area"
                            height={350}
                          />
                        </Card.Body>
                      </Card>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="about" title={t('About')}>
                <div className='mb-4'>
                  <h4>{t('About System')}</h4>
                  <p className={`text-font-muted ${ data?.lang == 'ar' ? 'ar-dir' : '' }`}>{data?.description}</p>
                  
                </div>

                <div>
                  <h4>{t('Provided By')}</h4>
                  <p className={`text-font-muted ${ data?.lang == 'ar' ? 'ar-dir' : '' }`}>{data?.provider_name}</p>
                </div>

                <div>
                  <h4>{t('Fields')}</h4>

                  {data?.fields &&
                    data?.fields.map((d: any, i: number) => (
                      <>
                        <p className={`${ data?.lang == 'ar' ? 'ar-dir' : '' }`} style={{margin: '10px 0 5px'}}>
                          <FaCircle color="fb5456" />
                          <span style={{margin: '0 10px'}}>
                            {d.name ?? ''}
                          </span>
                        </p>

                        {d.goals && (
                          <div className="goals" style={{marginLeft: '20px'}}>
                            {d.goals.map((goal: any, index: number) => {
                              return (
                                <div style={{margin: '0 10px'}} className={`${ data?.lang == 'ar' ? 'ar-dir' : '' }`}>
                                  <FaCircle color="#9f9f9f" size={12} />
                                  <span style={{margin: '0 10px'}}>
                                    {goal.name ?? ''}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </Tab>

              <Tab eventKey="history" title={t('History')}>
              {historyList.map((tracking: TrackingType) => {
                return (
                  <Col lg={12} className='p-0'>
                          <TrackingCard
                            tracking={tracking}
                            isPackage={tracking.tracking_type == 'multi'}
                            enrolled={true}
                          />
                        </Col>
                      );
                    })}
              </Tab>
            </Tabs>

            {data?.has_started_tracking == 0 && (
              <MainButton
                text="Start Now"
                style={{marginTop: 30}}
                onClick={() => {
                  StartTrack();
                }}
              />
            )}

          </Col>
        </Row>
      </Container>

      {/*assign user pop up*/}
      <Modal
        show={showAssignUsers}
        onHide={() => setShowAssignUsers(false)}
        centered>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className={classes.AuctionType}>
            <ul className="relative mb-3 w-full flex flex-wrap items-stretch justify-center p-0 hideScroll">
              {members.map((e: any, i: any) => (
                <li className="text-center" key={i}>
                  <input
                    type="radio"
                    name="test"
                    id={`name-${i}`}
                    value={e.id}
                    onChange={getAssignedUserVal}
                    disabled={e?.id == data?.selected_enrolled_user_id}
                    checked={e?.id == member}
                  />

                  <label htmlFor={`name-${i}`}>
                    <div>
                      <img
                        src={e.avatar ?? '/icons/manHolder.png'}
                        style={{
                          width: '50px',
                          height: '50px',
                          margin: 'auto',
                          borderRadius: '50%',
                        }}
                      />
                    </div>
                    <h6>{e.username}</h6>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={t('Switch Now')}
              disabled={member == data?.selected_enrolled_user_id}
              onClick={() => {
                redirectWithNewId();
              }}
            />
          </div>

          <div onClick={() => setShowAssignUsers(false)} className="w-100">
            <CancelButton text={t('Cancel')} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrackingDetailsAfterEnroll;
