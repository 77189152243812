import {Col, Container, Row} from 'react-bootstrap';
import TrackingCard from './components/TrackingCard';
import {useAppDispatch} from '../../hooks/hooks';
import {useEffect, useState} from 'react';
import {setLoading} from '../../store/userSlice';
import { discoverTrackingsList } from './Trackings.service'; 
import ItemsPagination from '../../UI/pagination/ItemsPagination';
import {TrackingType} from './interfaces/ITrackings';
import classes from './style/List.module.css'
import { CheckBoxTitle } from '../../UI/check-box-title/CheckBoxTitle';
import { colors } from '../../constants/colors';
import SearchButton from '../../components/search-button/SearchButton';

const FollowUpType = [
  {id: 'multi', name: 'package'},
  {id: 'single', name: 'single'},
];

const languages = [
  {id: 'ar', name: 'Arabic'},
  {id: 'en', name: 'English'},
];

const Provider = [
  {id: 'admin', name: 'Nurabi'},
  {id: 'consultant', name: 'Consultants'},
];

const Price = [
  {id: 'free', name: 'Free'},
  {id: 'paid', name: 'Paid'},
];

const UserTrackingsDiscover = () => {
  const dispatch = useAppDispatch();

  const [trackings, setTrackings] = useState([]);
  const [lastPage, SetLastPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const [languagesSelected, setLanguagesSelected] = useState<any>([]);
  const [followUpTypeSelected, setFollowUpTypeSelected] = useState<any>([]);
  const [providerSelected, setProviderSelected] = useState<any>([]);
  const [priceSelected, setPriceSelected] = useState<any>([]);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    //setPage(page)
    
    dispatch(setLoading(true));
    let result = await discoverTrackingsList(
      page,
      title,
      languagesSelected,
      followUpTypeSelected,
      providerSelected,
      priceSelected
      );
    if (result.response) {
      
      setTrackings(result.response.data.data);
      SetLastPage(result.response.data.page_meta.last_page);
      
      
    }
    dispatch(setLoading(false));
    
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      getData()
    }
  };

  const getLangVal = (value:any) => {
    setPage(1)
    let sourceArr = languagesSelected
    if (languagesSelected?.includes(value)) {
      let index = sourceArr.indexOf(value);
      sourceArr.splice(index, 1);
      setLanguagesSelected(sourceArr);
    } else {
      sourceArr.push(value)
      setLanguagesSelected(sourceArr);
    }
    console.log('languagesSelected', languagesSelected)
    getData()
    
  }

  const getFollowUpTypeVal = (value:any) => {
    setPage(1)
    let sourceArr = followUpTypeSelected
    if (followUpTypeSelected?.includes(value)) {
      let index = sourceArr.indexOf(value);
      sourceArr.splice(index, 1);
      setFollowUpTypeSelected(sourceArr);
    } else {
      sourceArr.push(value)
      setFollowUpTypeSelected(sourceArr);
    }
    console.log('followUpTypeSelected', followUpTypeSelected)
    getData()
    
  }

  const getProviderVal = (value:any) => {
    setPage(1)
    let sourceArr = providerSelected
    if (providerSelected?.includes(value)) {
      let index = sourceArr.indexOf(value);
      sourceArr.splice(index, 1);
      setProviderSelected(sourceArr);
    } else {
      sourceArr.push(value)
      setProviderSelected(sourceArr);
    }
    console.log('providerSelected', providerSelected)
    getData()
    
  }

  const getPriceVal = (value:any) => {
    setPage(1)
    let sourceArr = priceSelected
    if (priceSelected?.includes(value)) {
      let index = sourceArr.indexOf(value);
      sourceArr.splice(index, 1);
      setPriceSelected(sourceArr);
    } else {
      sourceArr.push(value)
      setPriceSelected(sourceArr);
    }
    console.log('priceSelected', priceSelected)
    getData()
    
  }

 

  return (
    <div>
      <Row>
      
        
      
      <Col xs={3}>
      <p className="filters-title">Filter</p>

      <div className="filter-content">
        <div className="filter-type">
          <p className="filter-type-title">Categories</p>
          <div className="filter-type-options">
            {FollowUpType.map(i => {
              return (
                <CheckBoxTitle
                  id={i?.name}
                  checked={followUpTypeSelected?.includes(i.id)}
                  title={i?.name}
                  setChecked={() => {
                    getFollowUpTypeVal(i.id)
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="filter-content">
        <div className="filter-type">
          <p className="filter-type-title">Provider</p>
          <div className="filter-type-options">
            {Provider.map(i => {
              return (
                <CheckBoxTitle
                  id={i?.name}
                  checked={providerSelected?.includes(i.id)}
                  title={i?.name}
                  setChecked={() => {
                    getProviderVal(i.id)
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="filter-content">
        <div className="filter-type">
          <p className="filter-type-title">Language</p>
          <div className="filter-type-options">
            {languages.map(i => {
              return (
                <CheckBoxTitle
                  id={i?.name}
                  checked={languagesSelected?.includes(i.id)}
                  title={i?.name}
                  setChecked={() => {
                    getLangVal(i.id)
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="filter-content">
        <div className="filter-type">
          <p className="filter-type-title">Price</p>
          <div className="filter-type-options">
            {Price.map(i => {
              return (
                <CheckBoxTitle
                  id={i?.name}
                  checked={priceSelected?.includes(i.id)}
                  title={i?.name}
                  setChecked={() => {
                    getPriceVal(i.id)
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      
      </Col>
      <Col xs={9}>
        <Row>
          <Col md={12}>
          <div className="mb-4 search-container">
          <img
            src="/icons/search.png"
            className="search-icon"
            alt="img"
            width={20}
          />
          <input
            type="text"
            placeholder="Search In Discover Systems"
            onChange={e => setTitle(e.target.value)}
            onKeyDown={handleKeyDown}
            className={` ${classes.ListSearch} form-control m-0 `}
            />
            <SearchButton getData={getData} />
        </div>
          </Col>
        {trackings.map((tracking: TrackingType) => {
          return (
            <Col lg={12} className='p-0'>
                    <TrackingCard
                      tracking={tracking}
                      isPackage={tracking.tracking_type == 'multi'}
                      enrolled={false}
                    />
                  </Col>
                );
              })}
              <Col lg={12}>
            <div className="pagination-container">
              <ItemsPagination
                pages={lastPage}
                current={page}
                changePage={page => {
                  setPage(page);
                }}
              />
            </div>
            </Col>
              </Row>
            </Col>
            
          </Row> 

          
    </div>
  );
};

export default UserTrackingsDiscover;
